import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { createBrowserHistory } from 'history';

// Auth redux actions
import { authErrorAction, loginSuccessAction, logoutSuccessAction } from 'store/auth/actions';

// API calls
import { api_appleLogin, api_facebookLogin, api_googleLogin, api_login, api_logout } from 'backend/api_calls';

// User object via local store
import UserSession from 'backend/user_session';
import { t } from 'i18next';

function* loginCustomer(action) {
	try {
		const response = yield call(api_login, action.args);

		UserSession.initWithJson(response);

		yield put(loginSuccessAction(response, 'Welcome back ' + UserSession.getName()));
	} catch (error) {
		if (error === 'Please confirm your account first to proceed' || error === 'Ο λογαριασμός σας δεν έχει επιβεβαιωθεί') {
			yield put(authErrorAction(error));
		} else {
			yield put(authErrorAction(t('login_error')));
		}
	}
}

function* AppleLoginCustomer(action) {
	try {
		const response = yield call(api_appleLogin, action.args);

		UserSession.initWithJson(response);

		yield put(loginSuccessAction(response, 'Welcome back ' + UserSession.getName()));

		// Goto profile page after successful login
		//createBrowserHistory().push("/profile");
		//window.location.reload();
	} catch (error) {
		yield put(authErrorAction(error));
	}
}

function* GoogleLoginCustomer(action) {
	try {
		const response = yield call(api_googleLogin, action.args);

		UserSession.initWithJson(response);

		yield put(loginSuccessAction(response, 'Welcome back ' + UserSession.getName()));

		// Goto profile page after successful login
		//createBrowserHistory().push("/profile");
		//window.location.reload();
	} catch (error) {
		yield put(authErrorAction(error));
	}
}

function* FacebookLoginCustomer(action) {
	try {
		const response = yield call(api_facebookLogin, action.args);

		UserSession.initWithJson(response);

		yield put(loginSuccessAction(response, 'Welcome back ' + UserSession.getName()));

		// Goto profile page after successful login
		//createBrowserHistory().push("/profile");
		//window.location.reload();
	} catch (error) {
		yield put(authErrorAction(error));
	}
}

function* logoutCustomer(action) {
	try {
		let args = {
			at: UserSession.getAccessToken(),
			rt: UserSession.getRefreshToken()
		};

		UserSession.clearData();

		if (args.at != null && args.rt != null) {
			const response = yield call(api_logout, args);
			yield put(logoutSuccessAction(response));
		}
	} catch (error) {
		yield put(authErrorAction(error));
	} finally {
		// Goto home page
		createBrowserHistory().push('/');
		window.location.reload();
	}
}

function* authSaga() {
	yield takeLatest('login', loginCustomer);
	yield takeLatest('apple-login', AppleLoginCustomer);
	yield takeLatest('google-login', GoogleLoginCustomer);
	yield takeLatest('facebook-login', FacebookLoginCustomer);
	yield takeEvery('logout', logoutCustomer);
}

export default authSaga;
