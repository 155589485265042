import { convertImageUrl_S_to_M } from 'backend/helper';
import { formatAmountValue } from 'common/helper';
import AddToWishlistButton from 'components/wishlist/AddToWishlistButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import noimage_png from '../../images/no-image.png';

export default function ProductBox(props) {
	const product = props.product;
	// Do NOT declare constant / let, except for state variables because their value will not be updated correctly when we re-render due to filter/sorting etc.
	const [sizes, setSizes] = useState([]);
	const [chosenColor, setChosenColor] = useState('');
	const [uniqueColors, setUniqueColors] = useState([]);
	const [firstImage, setFirstImage] = useState(noimage_png);
	const [secondImage, setSecondImage] = useState(noimage_png);
	const [price, setPrice] = useState(0);
	const [priceBD, setPriceBD] = useState(0);
	const [discount, setDiscount] = useState(0);

	useEffect(() => {
		const uniqueColors = [...new Map(props.product.dimensions.filter(item => item.cval).map(item => [item['cval'], item])).values()];
		setUniqueColors(uniqueColors);

		setFirstImage(convertImageUrl_S_to_M(props.product.img1) ?? noimage_png);
		setSecondImage(convertImageUrl_S_to_M(props.product.img2));
	}, [props.product.id]);

	useEffect(() => {
		if (uniqueColors.length > 0) {
			const firstColor = uniqueColors[0];
			const dimensionsIds = props.product.dimensions.filter(x => x.cval == firstColor.cval).map(y => y.id);
			const sizes = props.product.dimensions.filter(x => x.cval == null && dimensionsIds.includes(x.id));
			setChosenColor(firstColor.cval);
			setSizes(sizes);
			setColorPictures(sizes, firstColor);
		} else {
			setSizes(props.product.dimensions);
		}
	}, [uniqueColors]);

	useEffect(() => {
		let price = product.cprc;
		let priceBD = product.cprcbd;
		let discount = product.cdsc;

		if (chosenColor) {
			const dimension = product.dimensions.find(x => x.cval === chosenColor);
			if (dimension?.prc) {
				price = dimension.cprc;
				priceBD = dimension.cprcbd;
				discount = dimension.cdsc;
			}
		}

		setPrice(price);
		setPriceBD(priceBD);
		setDiscount(discount);
	}, [props.product.id, chosenColor]);

	const handleColorChange = color => {
		const dimensionsIds = props.product.dimensions.filter(x => x.cval == color).map(y => y.id);
		const sizes = props.product.dimensions.filter(x => x.cval == null && dimensionsIds.includes(x.id));

		setSizes(sizes);
		setChosenColor(color);
		setColorPictures(sizes, color);
	};

	const setColorPictures = (sizes, color) => {
		//if (color === [...uniqueColors][0]) {
		//    setFirstImage(props.product.img1);
		//    setSecondImage(props.product.img2);
		//    return;
		//}

		let imgArray = [];
		for (let i = 0; i < sizes.length; i++) {
			let size = sizes[i];
			if (size.img1) imgArray.push(size.img1);

			if (size.img2) imgArray.push(size.img2);

			if (size.img3) imgArray.push(size.img3);

			if (size.img4) imgArray.push(size.img3);

			if (imgArray.length > 1) break;
		}

		if (imgArray.length > 1) {
			setFirstImage(convertImageUrl_S_to_M(imgArray[0]));
			setSecondImage(convertImageUrl_S_to_M(imgArray[1]));
		} else if (imgArray.length === 1) {
			setFirstImage(convertImageUrl_S_to_M(imgArray[0]));
			setSecondImage(null);
		}
	};

	const showColors = dimensions => {
		if (dimensions != null && dimensions.length > 1) {
			// Display only the first 4 colors-variations of props.product.
			const colors = uniqueColors
				.filter((color, index) => index < 4)
				.map((color, index) => (
					<div key={index}>
						<input
							type="checkbox"
							onChange={() => handleColorChange(color.cval)}
							checked={color.cval == chosenColor}
							style={{ backgroundColor: color.chex }}
						/>
					</div>
				));

			// If props.product has more than four(4) color-variations add a link to Product-Page.
			if (uniqueColors.length > 4) {
				colors.push(
					<div key={uniqueColors.length} className="show-more-colors">
						<Link type="button" className="show-more-colors1" to={props.product.eurl}>
							+
						</Link>
					</div>
				);
			}

			return colors;
		}
		return null;
	};

	const showSizes = sizes => {
		if (sizes != null && sizes.length > 0) {
			return sizes.map((item, index) => (
				<Link
					type="button"
					className={item.qty && item.qty > 0 ? 'enabled' : 'disabled'}
					to={item.qty && item.qty > 0 ? props.product.eurl + '?bcd=' + item.bcd : '#'}
					key={props.product.id + '-' + index}>
					{item.svtxt}
				</Link>
			));
		}
		return null;
	};

	const showPrice = () => {
		return (
			<React.Fragment>
				<span className={`${!priceBD ? 'final-price' : 'price-af'}`}>{formatAmountValue(price)}</span>
				{priceBD && <span className="price-bd">{formatAmountValue(priceBD)}</span>}
			</React.Fragment>
		);
	};

	const showStatus = () => {
		let statuses = props.product.stslst?.filter(x => x.iv === true);
		if (!statuses || statuses.length === 0) return null;

		statuses.sort((a, b) => a.si - b.si);

		// Remove accents/diacritics so that uppercase works well
		statuses = statuses.map(x => x.snm.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

		return (
			<div className="product-statuses">
				{statuses.map(status => (
					<span key={status}>{status}</span>
				))}
			</div>
		);
	};

	return (
		<div className="product-box" id={props.product.id}>
			<AddToWishlistButton product={props.product} updateWishList={props.updateWishList} />
			<div className="prod-img">
				<Link
					to={props.product.eurl}
					onClick={() => {
						props.fromWidget && props.closeCart();
					}}>
					<img src={firstImage} alt={props.product.nm} loading="lazy" />
					{secondImage ? <img className="on-hover" src={secondImage} alt={props.product.nm} loading="lazy" /> : null}
				</Link>
			</div>
			<div className="product-box-content">
				<div className="special-labels">
					{discount && discount > 1 ? (
						<div className="label-item">
							<span>{'-' + Math.abs(Math.floor(discount)) + '%'}</span>
						</div>
					) : (
						<div className="label-item empty-item"></div>
					)}
				</div>

				<div className="prod-details">
					<div className="flex-col">
						<div>
							<div className="brand-logo">
								<span>{props.product.mfnm}</span>
							</div>
							<h3 title={props.product.nm}>{props.product.nm}</h3>
							<div className="prod-price">{showPrice()}</div>
							<div className="prod-colors flex-col">{showColors(props.product.dimensions)}</div>
						</div>
					</div>
					{sizes.length > 0 ? (
						<div className="quick-buy btn-hover">
							<div className="prod-dim flex-col">{showSizes(sizes)}</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

ProductBox.propTypes = {
	product: PropTypes.object,
	closeCart: PropTypes.func,
	fromWidget: PropTypes.bool
};
