import useWindowSize from 'components/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const classRemover = selector => {
	var elems = document.querySelectorAll(selector);
	[].forEach.call(elems, function (el) {
		el.classList.remove('active');
	});
};

const MenuItem = ({ menuItem, menuItems }) => {
	const { t } = useTranslation();
	const { width } = useWindowSize();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenuEnter = () => {
		setIsMenuOpen(true);
	};

	const handleMenuLeave = () => {
		setIsMenuOpen(false);
	};

	if (menuItems.length === 0 || menuItem === undefined) return null;

	const handleMobileClick = e => {
		if (e.target.classList.contains('menu-item')) {
			if (e.target.classList.contains('active')) {
				e.target.classList.toggle('active');
				return;
			}
			classRemover('.submenu-item');
			classRemover('.menu-subparents');
			classRemover('.menu-subparents-titles');
			classRemover('.main-item');
			classRemover('.menu-item');
			e.target.classList.add('active');
		}
		if (e.target.classList.contains('menu-subparents')) {
			if (e.target.classList.contains('active')) {
				e.target.classList.toggle('active');
				return;
			}
			classRemover('.submenu-item');
			classRemover('.menu-subparents');
			classRemover('.menu-subparents-titles');
			e.target.classList.add('active');
		}
	};

	const closeMenu = (e, type) => {
		document.querySelector('.menu-close-mobile').click();
		if (type === 'main') {
			classRemover('.menu-item');
			classRemover('.main-item');
			classRemover('.menu-subparents');
			classRemover('.menu-subparents-titles');
		}
		if (type === 'sub') {
			classRemover('.menu-subparents');
			classRemover('.menu-subparents-titles');
		}
		classRemover('.submenu-item');
		e.target.classList.toggle('active');
		setIsMenuOpen(false);
	};

	return (
		<>
			{width <= 1420 ? (
				<div
					className={menuItems.filter(x => x.pid === menuItem.id).length > 0 ? 'menu-item item-has-children' : 'menu-item'}
					id={menuItem.tl}
					onClick={handleMobileClick}
					key={menuItem.id}>
					<Link to={typeof menuItem.nu === 'string' ? menuItem.nu : '/'} className="main-item" onClick={e => closeMenu(e, 'main')}>
						{menuItem.tl}
					</Link>
					{menuItems.filter(x => x.pid === menuItem.id).length > 0 && (
						<div className="sub-menu">
							<div className="sub-wrapper">
								<div className="all-brands-part">
									{menuItems
										.filter(x => x.pid === menuItem.id)
										.map(item => (
											<div
												className={
													menuItems.filter(x => x.pid === item.id).length > 0
														? 'menu-subparents subparents-has-children'
														: 'menu-subparents'
												}
												key={item.id}>
												<Link
													className="menu-subparents-titles"
													to={typeof item.nu === 'string' ? item.nu : '/'}
													onClick={e => closeMenu(e, 'sub')}>
													{item.tl}
												</Link>
												<div className="menu-subchildren">
													{menuItems
														.filter(x => x.pid === item.id)
														.map(subitem => (
															<Link
																key={subitem.id}
																className="submenu-item"
																to={typeof subitem.nu === 'string' ? subitem.nu : '/'}
																onClick={e => closeMenu(e, 'sub1')}>
																{subitem.tl}
															</Link>
														))}
													{menuItems.filter(x => x.pid === item.id).length > 0 ? (
														<Link
															className="menu-subparents-see-all"
															to={typeof item.nu === 'string' ? item.nu : '/'}
															onClick={e => closeMenu(e, 'sub1')}>
															{t('see_all_small_caps')}
														</Link>
													) : null}
												</div>
											</div>
										))}
								</div>
								{typeof menuItem.bd === 'string' && (
									<div
										className="top-brands-part"
										dangerouslySetInnerHTML={{
											__html: menuItem.bd
										}}></div>
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				<div
					className={menuItems.filter(x => x.pid === menuItem.id).length > 0 ? 'menu-item item-has-children' : 'menu-item'}
					id={menuItem.tl}
					onMouseEnter={handleMenuEnter}
					onMouseLeave={handleMenuLeave}
					key={menuItem.id}>
					<Link to={typeof menuItem.nu === 'string' ? menuItem.nu : '/'} className="main-item" onClick={closeMenu}>
						{menuItem.tl}
					</Link>
					{menuItems.filter(x => x.pid === menuItem.id).length > 0 && isMenuOpen && (
						<div className="sub-menu">
							<div className="sub-wrapper">
								<div className="all-brands-part">
									{menuItems
										.filter(x => x.pid === menuItem.id)
										.map(item => (
											<div
												className={
													menuItems.filter(x => x.pid === item.id).length > 0
														? 'menu-subparents subparents-has-children'
														: 'menu-subparents'
												}
												key={item.id}>
												<Link className="menu-subparents-titles" to={typeof item.nu === 'string' ? item.nu : '/'} onClick={closeMenu}>
													{item.tl}
												</Link>
												<div className="menu-subchildren">
													{menuItems
														.filter(x => x.pid === item.id)
														.map(subitem => (
															<Link
																key={subitem.id}
																className="submenu-item"
																to={typeof subitem.nu === 'string' ? subitem.nu : '/'}
																onClick={closeMenu}>
																{subitem.tl}
															</Link>
														))}
													{menuItems.filter(x => x.pid === item.id).length > 0 ? (
														<Link className="menu-subparents-see-all" to={typeof item.nu === 'string' ? item.nu : '/'} onClick={closeMenu}>
															{t('see_all_small_caps')}
														</Link>
													) : null}
												</div>
											</div>
										))}
								</div>
								{typeof menuItem.bd === 'string' && (
									<div
										className="top-brands-part"
										dangerouslySetInnerHTML={{
											__html: menuItem.bd
										}}></div>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

MenuItem.propTypes = {
	menuItem: PropTypes.object,
	menuItems: PropTypes.array,
	index: PropTypes.number
};

export default MenuItem;
