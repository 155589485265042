import { notifyError } from 'components/common/ToastMessages';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appleLoginAction } from 'store/actions';
import appleSVG from '../../images/apple.svg';

const AppleLoginButton = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		window.AppleID.auth.init({
			clientId: 'com.undergroundshop.apple-login',
			scope: 'email name',
			redirectURI: 'https://undergroundshop.gr/applelogin',
			state: 'state',
			usePopup: true,
			response_mode: 'form_post'
		});
	}, []);

	const handleSignIn = async () => {
		try {
			const response = await window.AppleID.auth.signIn();
			dispatch(appleLoginAction(response));
		} catch (error) {
			console.log(error);
			if (error?.error === 'popup_closed_by_user') return;
			notifyError('Υπάρχει ήδη λογαριασμός που χρησιμοποιεί αυτό το email');
		}
	};

	return (
		<button onClick={handleSignIn} className="flex-col">
			<img src={appleSVG} alt="apple login" />
			Apple
		</button>
	);
};

export default AppleLoginButton;
