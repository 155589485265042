export const convertUTCDateToLocalDate = date => {
	let newDate = new Date(date);
	newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
	return newDate;
};

export const dateFormatShort = {
	dateStyle: 'short',
	timeStyle: 'short',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export const dateFormatLong = {
	dateStyle: 'medium',
	timeStyle: 'medium',
	timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export function formatDateString(d) {
	if (d == null) return '';

	// Add UTC Offset, else it parses is as local datetime
	d = d.slice(0, 19) + '.000Z';

	return new Intl.DateTimeFormat('el-GR', dateFormatLong).format(new Date(d));
}

export const getGenderLabel = genderId => {
	let gender = '';

	if (genderId == 1) {
		gender = 'male';
	} else if (genderId == 2) {
		gender = 'female';
	} else if (genderId == 3) {
		gender = 'other';
	} else if (genderId == 4) {
		gender = 'noDisclose';
	}

	return gender;
};

export const isEmpty = obj => {
	return Object.keys(obj).length === 0;
};

export const sortLabels = arr => {
	return arr.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};

export const emptyProfile = () => {
	return {
		eml: '',
		mob: '',
		dob: '',
		gd: '',
		fn: '',
		ln: '',
		id: ''
	};
};

export function formatAmountValue(value) {
	if (value != null) {
		return value.toFixed(2).toString() + '€';
	}

	return '';
}

export function formatAmount(value) {
	if (value) {
		return parseFloat(value.toFixed(2));
	}

	return value;
}

export const attachIndexesToProducts = (products, startIndexFrom = 0) => {
	const test = products.map((product, index) => {
		return { ...product, index: index + startIndexFrom };
	});

	return test;
};

//export function calculateProductPrice(product, dimensionId) {
//	if (dimensionId) {
//		const dimension = product.dimensions.find(x => x.id == dimensionId);

//		if (dimension.prc) {
//			if (dimension.prcbd > 0) {
//				return formatAmountValue(dimension.prc);
//			}

//			if (dimension.dsc > 0 && dimension.dsc < 100) {
//				return formatAmountValue(dimension.prc - (dimension.prc * dimension.dsc) / 100);
//			}
//		}
//	}

//	if (product.prcbd > 0) {
//		return formatAmountValue(product.prc);
//	}
//	if (product.dsc > 0 && product.dsc < 100) {
//		return formatAmountValue(product.prc - (product.prc * product.dsc) / 100);
//	}

//	return formatAmountValue(product.prc);
//}

//export function calculateProductPriceValue(product, dimensionId) {
//	if (dimensionId) {
//		const dimension = product.dimensions.find(x => x.id == dimensionId);

//		if (dimension.prc) {
//			if (dimension.prcbd > 0) {
//				return dimension.prc;
//			}

//			if (dimension.dsc > 0 && dimension.dsc < 100) {
//				return dimension.prc - (dimension.prc * dimension.dsc) / 100;
//			}
//		}
//	}

//	if (product.prcbd > 0) {
//		return product.prc;
//	}
//	if (product.dsc > 0 && product.dsc < 100) {
//		return product.prc - (product.prc * product.dsc) / 100;
//	}

//	return product.prc;
//}

//export function calculateProductPriceBD(product, dimensionId) {
//	if (dimensionId) {
//		const dimension = product.dimensions.find(x => x.id == dimensionId);

//		if (dimension.prc) {
//			if (dimension.prcbd > 0) {
//				return formatAmountValue(dimension.prcbd);
//			}

//			if (dimension.dsc > 0 && dimension.dsc < 100) {
//				return formatAmountValue(dimension.prc);
//			}
//		}
//	}

//	if (product.prcbd > 0) {
//		return formatAmountValue(product.prcbd);
//	}
//	if (product.dsc > 0 && product.dsc < 100) {
//		return formatAmountValue(product.prc);
//	}

//	return null;
//}

//export function calculateProductPriceBDValue(product, dimensionId) {
//	if (dimensionId) {
//		const dimension = product.dimensions.find(x => x.id == dimensionId);

//		if (dimension && dimension.prc) {
//			if (dimension.prcbd > 0) {
//				return dimension.prcbd;
//			}

//			if (dimension.dsc > 0 && dimension.dsc < 100) {
//				return dimension.prc;
//			}
//		}
//	}

//	if (product.prcbd > 0) {
//		return product.prcbd;
//	}
//	if (product.dsc > 0 && product.dsc < 100) {
//		return product.prc;
//	}

//	return null;
//}

//export function calculateProductDiscountPercentage(product, dimensionId) {
//	if (dimensionId) {
//		const dimension = product.dimensions.find(x => x.id == dimensionId);

//		if (dimension.prc) {
//			if (dimension.prcbd > 0) {
//				let discount = ((dimension.prcbd - dimension.prc) / dimension.prcbd) * 100;
//				return discount;
//			}

//			if (dimension.dsc > 0 && dimension.dsc < 100) {
//				return dimension.dsc;
//			}
//		}
//	}

//	if (product.prcbd > 0) {
//		let discount = ((product.prcbd - product.prc) / product.prcbd) * 100;
//		return discount;
//	}
//	if (product.dsc > 0 && product.dsc < 100) {
//		return product.dsc;
//	}

//	return null;
//}

//export const setPassVisibillityClickListener = () => {
//    $('#togglePassVisibillity').click(function () {
//        let passInput = $(this).parent().find('input');
//        let showIcon = $(this).find('#showPassIcon');
//        let hideIcon = $(this).find('#hidePassIcon');

//        if ($(passInput).prop("type") == 'text') {
//            $(passInput).prop("type", "password");
//            $(showIcon).show();
//            $(hideIcon).hide();
//        } else {
//            $(passInput).prop("type", "text");
//            $(hideIcon).show();
//            $(showIcon).hide();
//        }
//    });
//};

export function normalize(text) {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export const initFacebookSdk = () => {
	return new Promise(resolve => {
		// wait for facebook sdk to initialize before starting the react app
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID,
				cookie: false,
				xfbml: true,
				version: 'v9.0'
			});

			resolve();
		};

		// load facebook sdk script
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
};
